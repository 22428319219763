
import { Vue, Component } from 'vue-property-decorator';

import $app from '@/plugins/modules';
import { Common } from '@/common';
import { System } from '@/modules/system';
import { Clinic } from '@/modules/clinic'
import { DamageMode, IDamage, IDamageFilter, IDamageSearch } from '../types';
import { ITableHeader, IPagination, ISimpleDataItem } from '../../system/types';

@Component
export default class DamagesView extends Vue {
  common = $app.module(Common);
  system = $app.module(System);
  clinic = $app.module(Clinic);

  loading = false;
  showGroups = false;

  showGroupEdit = false;
  group: ISimpleDataItem | null = null;

  groups: Array<ISimpleDataItem> = [];
  filter: IDamageFilter = $app.clone(this.clinic.$store.DamageSearch.filter);

  showItem = false;
  processItem = false;
  selectedItem: IDamage | null = null;

  get headers(): Array<ITableHeader> {
    return [
      {
        text: '',
        value: 'DamageTooth',
        align: 'center',
        width: '5%'
      },
      {
        text: $app.i18n('dplus.manipulations.Code'),
        value: 'DamageCode',
        align: 'left',
        width: '10%'
      },
      {
        text: $app.i18n('system.Name'),
        value: 'DamageName',
        align: 'left',
        width: '35%'
      },
      {
        text: $app.i18n('dplus.manipulations.Group'),
        value: 'DamageGroupName',
        align: 'left',
        width: '30%'
      },
      {
        text: $app.i18n('dplus.damage.DamageSurf'),
        value: 'DamageSurf',
        align: 'center',
        width: '10%'
      },
      {
        text: $app.i18n('dplus.damage.DamageGum'),
        value: 'DamageGum',
        align: 'center',
        width: '10%'
      }
    ];
  }

  get search(): IDamageSearch {
    return this.clinic.$store.DamageSearch;
  }

  async findNext(pagination: IPagination) {
    if (!this.loading) {
      this.loading = true;
      await this.clinic.$store.findDamages(pagination);
      this.loading = false;
    }
  }

  async findFirst() {
    this.loading = false;
    const request = this.system.$module.newSearch(this.clinic.$store.ManipulationSearch, this.filter);
    this.clinic.$store.setDamageSearch({
      items: [],
      filter: request
    });
    this.findNext(request);
  }

  async store(item: IDamage) {
    this.processItem = true;
    try {
      await this.clinic.$store.storeDamage(item);
      this.showItem = false;
    } catch (err) {
      $app.pushError(err);
    }
    this.processItem = false;
  }

  async save(item: IDamage) {
    this.processItem = true;
    try {
      await this.clinic.$store.storeDamage(item);
    } catch (err) {
      $app.pushError(err);
    }
    this.processItem = false;
  }

  async remove(item: IDamage) {
    this.processItem = true;
    try {
      await this.clinic.$store.removeDamage(item);
      this.showItem = false;
    } catch (err) {
      $app.pushError(err);
    }
    this.processItem = false;
  }

  async open(item: IDamage) {
    this.selectedItem = item;
    this.showItem = await this.reloadGroups();
  }

  async append() {
    this.selectedItem = {
      id: null,
      code: 'CODE',
      name: 'NEW DAMAGE',
      group: null,
      groupName: null,
      mode: DamageMode.None,
      surface: false,
      gum: false,
      curing: null,
      manipulations: [],
      processing: false
    };
    this.showItem = await this.reloadGroups();
  }

  async openGroups() {
    this.showGroups = await this.reloadGroups();
  }

  async reloadGroups(): Promise<boolean> {
    let result = false;
    this.loading = true;
    try {
      this.groups = await $app.get('/api/clinics/damages/groups');
      result = true;
    } catch (error) {
      $app.pushError(error)
    }
    this.loading = false;
    return result;
  }

  openGroup(item: ISimpleDataItem) {
    this.group = item;
    this.showGroupEdit = true;
  }

  async addGroup() {
    this.loading = true;
    try {
      let item: ISimpleDataItem = {
        id: null,
        code: '',
        name: 'NEW GROUP',
        descr: '',
        processing: false
      }
      item = await $app.post('/api/clinics/damages/groups', item)
      this.groups = [item].concat(this.groups);
    } catch (error) {
      $app.pushError(error)
    }
    this.loading = false;
  }

  async storeGroup(item: ISimpleDataItem) {
    item = await $app.post('/api/clinics/damages/groups', item);
    this.group!.id = item.id;
    this.group!.code = item.code;
    this.group!.name = item.name;
    this.group!.descr = item.descr;
    this.showGroupEdit = false;
  }

  async removeGroup(index: number) {
    const item = this.groups[index];
    if (item) {
      item.processing = true;
      await $app.delete('/api/clinics/manipulations/groups/' + item.id);
      this.groups.splice(index, 1);
    }
  }

  mounted() {
    if (this.search.filter.totals === 0) {
      this.findFirst();
    }
  }
}
